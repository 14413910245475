export default {
	namespaced: true,
	state() {
		return {
			
		}
	},
	mutations: {
	
	},
	actions: {
	
	},
	getters: {

	},
}
