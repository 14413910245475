<template>
  <div>
    <router-link to="/instalation" class="circle"
      ><i class="fas fa-home"></i
    ></router-link>

    <div class="main-content">
      <svg
        style="height: 100%"
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#2779a7"
          fill-opacity="1"
          d="M0,128L48,117.3C96,107,192,85,288,74.7C384,64,480,64,576,85.3C672,107,768,149,864,170.7C960,192,1056,192,1152,202.7C1248,213,1344,235,1392,245.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <div class="top-text">
        <h1>Realizacje</h1>
        <p style="font-size: 1.2rem; font-weight: 600">
          Ukończyliśmy wiele projektów którymi możemy się pochwalic. Każdy
          zakończony sukcesem!:
        </p>
      </div>
      <div class="top-picture"></div>
      <div class="bg-shadow"></div>
    </div>

    <div class="body-content">
      <div class="offer-box" v-for="(item, idx) in offer" :key="idx">
        <svg
          style="height: 100%"
          xmlns="https://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#2779a7"
            fill-opacity="1"
            d="M0,128L48,117.3C96,107,192,85,288,74.7C384,64,480,64,576,85.3C672,107,768,149,864,170.7C960,192,1056,192,1152,202.7C1248,213,1344,235,1392,245.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
        <div class="main-text">
          <h2>{{ item.title }}</h2>
          <p style="font-weight: bold">
            {{ item.text
            }}<span style="font-weight: 400">{{ item.subText }}</span>
          </p>
          <p style="font-weight: bold">
            {{ item.text2 ? item.text2 : ''
            }}<span style="font-weight: 400">{{
              item.subText2 ? item.subText2 : ''
            }}</span>
          </p>
          <p style="font-weight: bold">
            {{ item.text3 ? item.text3 : ''
            }}<span style="font-weight: 400">{{
              item.subText3 ? item.subText3 : ''
            }}</span>
          </p>
          <p style="font-weight: bold">
            {{ item.text4 ? item.text4 : ''
            }}<span style="font-weight: 400">{{
              item.subText4 ? item.subText4 : ''
            }}</span>
          </p>
          <p style="font-weight: bold">
            {{ item.text5 ? item.text5 : ''
            }}<span style="font-weight: 400">{{
              item.subText5 ? item.subText5 : ''
            }}</span>
          </p>
          <p style="font-weight: bold">
            {{ item.text6 ? item.text6 : ''
            }}<span style="font-weight: 400">{{
              item.subText6 ? item.subText6 : ''
            }}</span>
          </p>
          <p style="font-weight: bold">
            {{ item.text7 ? item.text7 : ''
            }}<span style="font-weight: 400">{{
              item.subText7 ? item.subText7 : ''
            }}</span>
          </p>
          <p style="font-weight: bold">
            {{ item.text8 ? item.text8 : ''
            }}<span style="font-weight: 400">{{
              item.subText8 ? item.subText8 : ''
            }}</span>
          </p>
        </div>
      </div>
      <section id="image-carousel" class="splide" aria-label="Beautiful Images">
        <h2 style="padding: 0 1em">Galeria</h2>
        <div class="splide__track">
          <ul class="splide__list">
            <li class="splide__slide">
              <img
                src="./img/galeria1.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria10.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria11.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria12.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria13.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria14.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria15.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria16.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria17.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria2.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria4.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria44.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria55.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria6.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria7.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria8.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
            <li class="splide__slide">
              <img
                src="./img/galeria9.jpg"
                alt="zdjęcie projektu"
                loading="lazy"
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
    <footer style="overflow: hidden">
      <div class="footer">
        <div class="footer-logo">
          <img
            src="./img/logoPsystemorg.png"
            alt="logo psystem"
          />
        </div>
        <div>
          <h4>Informacje</h4>
          <p>Paweł Picheta</p>
          <p>P-System</p>
          <p>ul. Związkowa 3</p>
          <p>PL 66-200 Świebodzin</p>
          <p>NIP: 927-187-56-14</p>
        </div>
        <div>
          <h4>Kontakt</h4>
          <a href="tel:+48600499205">M : +48 600 499 205</a>
          <a href="tel:+48683825302">F : +48 68 382 53 02</a>
          <a href="mailto:biuro@psystem.pl" target="_blank">biuro@psystem.pl</a>
        </div>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          background-color: #505050;
          color: white;
        "
      >
        &copy; {{ copyright }}
      </div>
    </footer>
  </div>
</template>
<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Splide from '@splidejs/splide';
export default {
  data() {
    return {
      offer: [
        {
          title: 'Realizacja w Ciborzu',
          text: 'Termomodernizacja Budynku Pralni',
          subText:
            '- Wykonanie Instalacji Fotowoltaicznej o mocy 40 Kw i wymiana Instalacji Elektrycznej',
          text2: 'Termomodernizacja  budynku Administracji',
          subText2:
            '- Wymiana Instalacji elektrycznej i Automatyka Węzła Ciepłowniczego',
          text3: 'Wojewódzki Szpital dla Nerwowo i Psychicznie Chorych',
          subText3:
            '- Monitoring WizyjnyKamery Nowej Generacji Ip, Wykonanie Sieci Światłowodowych, Wykonanie Instalacji Teletechnicznych',
        },
        {
          title: 'Gmina Świebodzin',
          text: 'Rozbudowa Domu Kultury ',
          subText:
            'System Sygnalizacji Ppoż , Kompletna Instalacja Elektryczna i Teletechniczna Wewnętrzna i Zewnetrzna',
          text2: 'Budowa Przedszkola i Żłobka Ul. Akacjowa w Świebodzinie ',
          subText2:
            'Wykonanie kompleksowej Instalacji elektrycznej i Teletechnicznej wraz z Systemem Sygnalizacji Pożaru',
          text3: 'Budowa Sali Wiejskiej Witosław',
          subText3: 'Wykonanie Instalacji Elektrycznej i Teletechnicznej',
          text4: 'Przedszkole Nr. 1 w Świebodzinie',
          subText4: 'Wykonanie Systemu Sygnalizacji Pożaru',
          text5: 'Publiczna Szkoła Podstawowa Nr. 3 w Świebodzinie ',
          subText5: 'Modernizacja Monitoringu Wizyjnego',
          text6: 'Rozbudowa Szkoły Podstawowej im. J. Korczaka',
          subText6:
            'Wykonanie kompleksowej  Instalacji Elektrycznej i Teletechnicznej.',
          text7: 'Szkoła Muzyczna Świebodzinie ',
          subText7:
            'Wykonanie Kompleksowej Instalacji Elektrycznej i Teletechnicznej  wraz Systemem PPOŻ',
          text8: 'Lubuskie Centrum Ortopedii W Świebodzinie ',
          subText8:
            'System Sygnalizacji Pożaru + Oświetlenie Awaryjne i Ewakuacyjne',
        },
        {
          title: 'Gmina Deszczno ',
          text: 'Budowa Przedszkola i Żłobka w Ulimiu.',
          subText: 'Wykonanie Instalacji Elektrycznych i Teletechnicznych',
          text2: 'Przedszkole w Ulim',
          subText2: 'Wykonanie Instalacji fotowoltaicznej o Mocy 20 Kw',
        },
        {
          title: 'Firmy',
          text: 'Firma Renago Hala Magazynowa ',
          subText: 'Wykonanie Instalacji elektrycznej',
          text2: 'Firma Renago Biurowiec ',
          subText2:
            'Wykonanie Instalacji Elektrycznej i Teletechnicznej wraz z Monitoringiem Wizyjnym Ip',
          text3: 'Firma Renago Rozbudowa Hotelu Siesta  w Świebodzinie ',
          subText3: 'Wykonanie Instalacji Elektrycznej i  Teletechnicznej',
          text4: 'Firma Lokertrans Budowa Obiektu Biurowego wraz z garażami ',
          subText4:
            'Wykonanie Instalacji elektrycznej Wewnętrznej i Zewnętrznej wraz z instalacją Teletechniczną',
          text5: 'Firma Lokertrans Budowa Dwóch Hal Magazynowych ',
          subText5:
            'Wykonanie Instalacji Elektrycznej wraz z Monitoringiem Wizyjnym IP',
          text6: 'LokerTrans',
          subText6:
            'Wykonanie Zewnętrznych linii zasilających do Budowanych Hal Magazynowych',
        },
      ],
      showNavOffer: false,
      per: 1,
      splide:false
    };
  },
  computed: {
    copyright() {
      const year = new Date().getFullYear();
      return `${year} DCD Dawid Haczela`;
    },
  },
  beforeMount() {
    if (window.innerWidth > 500) {
      this.per = 3;
    }
  },
  mounted() {
    this.splide = new Splide('#image-carousel', {
      type: 'loop',
      perPage: this.per,
      gap: '1rem',
      autoplay: true,
    });
    this.splide.mount();

    window.scrollTo(0, 0);
  },
 
  beforeUnmount() {
    // Usuwa instancję Splide przed zniszczeniem komponentu
    this.splide.destroy();
    this.splide = null;
  },
};
</script>
<style scoped>
.splide__track {
  height: auto;
  width: 95%;
  margin: auto;
  padding: 2em 0;
}
.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.splide {
  overflow: hidden;
  max-width: 1200px;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translateX(200%);
    opacity: 0;
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
.main-content {
  background: #1488cc;
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;

  margin: auto;
}

.top-picture {
  position: absolute;
  top: 80px;
  right: px;
  height: 70%;
  width: 100%;
  background-image: url('./img/realisations.jpg');
  background-position: center;
  background-size: cover;
  clip-path: polygon(100% 0%, 85% 52%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  z-index: 10;
}
.bg-shadow {
  position: absolute;
  top: 80px;
  right: px;
  height: 70%;
  width: 100%;
  background-color: rgba(66, 68, 90, 0.6);
  clip-path: polygon(100% 0%, 85% 52%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  z-index: 10;
}
.main-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
  z-index: 20;
  height: 100%;
  padding: 1em 0;
  overflow-x: auto;
}
.top-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
  z-index: 20;
  padding: 0.5em;
}
.body-content {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}
.offer-box {
  overflow: hidden;
  background: #1488cc;

  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  position: relative;
  /* padding:.5em 2em; */
  /* height:500px;
width:400px; */
  margin: 1em 0;
  min-height: 300px;
  width: 90%;
  border-radius: 8px;
}
.main-text h2,
.main-text p,
.main-text h1 {
  padding: 0 0.5em;
}
.circle {
  cursor: pointer;
  background: white;
  position: fixed;
  top: 2%;
  left: 2%;
  width: 50px;
  height: 50px;
  padding: 1em;
  border-radius: 50%;
  box-shadow: 0 0 10px #1488cc;
  display: flex;
  justify-content: center;
  z-index: 100;
}
.circle-offer {
  background-image: url('./img/alarm\ \(2\).png');
  cursor: pointer;
  background-size: cover;
  border: 2px solid yellow;
  position: fixed;
  top: 2%;
  right: 2%;
  width: 50px;
  height: 50px;
  padding: 1em;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  z-index: 100;
}
.circle-offer-alarm {
  cursor: pointer;
  background-image: url('./img/cable.png');
  background-size: cover;
  border: 2px solid yellow;
  position: fixed;
  top: 10%;
  right: 2%;
  width: 50px;
  height: 50px;
  padding: 1em;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  z-index: 100;
}
.circle-offer-lock {
  cursor: pointer;
  background-image: url('./img/zabezpieczenia.png');
  background-size: cover;
  border: 2px solid yellow;
  position: fixed;
  top: 18%;
  right: 2%;
  width: 50px;
  height: 50px;
  padding: 1em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  z-index: 100;
}
.circle-offer-monitoring {
  cursor: pointer;
  background-image: url('./img/monitoringcam.png');
  background-size: cover;
  border: 2px solid yellow;
  position: fixed;
  top: 26%;
  right: 2%;
  width: 50px;
  height: 50px;
  padding: 1em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  z-index: 100;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background-color: #1488cc;
  box-shadow: 0 0 10px white;
}
.footer {
  padding: 2em 0;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  background-color: #5d5d5d;
}
.footer div {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  color: #eaeaea;
  font-weight: 200;
}
.footer a {
  text-decoration: none;
  color: #eaeaea;
  cursor: pointer;
  padding: 0.2em 0;
}
h4 {
  color: #eaeaea;
}
.footer p {
  padding: 0;
  text-align: center;
  margin: 0;
}
.footer-logo {
  width: 100% !important;
}
.footer-logo img {
  width: 200px;
  object-fit: cover;
}
@media only screen and (min-width: 960px) {
  .main-content {
    height: 600px;
  }
  .body-content {
    width: 1200px;
    margin: auto;
    margin-bottom: 2em;
  }
  .splide__track {
    max-height: 700px;
  }
  .top-picture {
    width: 60%;
    left: 50%;
    transform: translate(-50%);
  }
  .bg-shadow {
    width: 90%;
    left: 60px;
  }
}
</style>
