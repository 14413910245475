<template>
   <header>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <title>Transport i Instalacje Elektryczne</title>
      <meta name="Transport i Instalacje" content="Oferujemy kompleksowe usługi transportowe i wynajem busów oraz projektowanie, montaż oraz konserwację instalacji elektrycznych. Skontaktuj się z nami i uzyskaj najlepsze rozwiązania.">
      <link rel="canonical" :href="canonicalUrl">
    </header>
  <main>   
    <router-view></router-view>
  </main>
</template>

<script>


export default {
  data() {
    return {
      canonicalUrl: 'https://wwww.psystem.pl'
    }
  }
  
}

</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,900;1,100&family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;800&family=Montserrat:ital,wght@0,300;0,500;0,900;1,100&family=Righteous&family=Sacramento&display=swap');





* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  
}
</style>