<template>
    <div v-if="!accepted" class="cookie-notice">
      <p>
        Ta strona wykorzystuje pliki cookies. 
        Kliknij przycisk "Akceptuj", aby zaakceptować politykę cookies.
      </p>
      <button @click="accept">Akceptuj</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        accepted: false
      };
    },
    methods: {
      accept() {
        this.accepted = true;
        localStorage.setItem("cookie-accepted", true);
      }
    },
    mounted() {
      if (localStorage.getItem("cookie-accepted")) {
        this.accepted = true;
      }
    }
  };
  </script>
  
  <style>
  .cookie-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
  </style>