import { createRouter, createWebHistory } from 'vue-router';

import FirstPageInstalation from './pages/instalation/FirstPageInstalation.vue';
import AlarmInstalation from './pages/instalation/AlarmInstalation'
import ElectricInstalation from './pages/instalation/ElectricInstalation.vue';
import MainPage from './components/layout/MainPage.vue';
import FirstPageTransport from './pages/instalation/FirstPageTransport';
import Projects from './pages/instalation/Projects';
import TeleInstalation from './pages/instalation/TeleInstalation';
import Monitoring from './pages/instalation/Monitoring';
import Fotowoltaika from './pages/instalation/Fotowoltaika';
// import CoachRegistation from './pages/coaches/CoachRegistration.vue';
// import ContactCoach from './pages/requests/ContactCoach.vue';
// import RequestsReceived from './pages/requests/RequestsReceived.vue';
import NotFound from './pages/NotFound.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/',name:'MainPage', component: MainPage },
    { path: '/instalation', component: FirstPageInstalation },
    { path: '/oferta_instalacje', component: ElectricInstalation },
    { path: '/oferta_alarmy', component: AlarmInstalation},
    { path: '/transport', component: FirstPageTransport},
    { path: '/realizacje', component: Projects},
    { path: '/oferta_teleinstalacje', component: TeleInstalation},
    { path: '/oferta_monitoring', component: Monitoring},
    { path: '/oferta_fotowoltaika', component: Fotowoltaika},
    // {
    //   path: '/coaches/:id',
    //   component: CoachDetail,
    //   children: [
    //     { path: 'contact', component: ContactCoach } // /coaches/c1/contact
    //   ]
    // },
    // { path: '/register', component: CoachRegistation },
    // { path: '/requests', component: RequestsReceived },
    { path: '/:notFound(.*)', component: NotFound }
  ]
});

export default router;
